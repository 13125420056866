
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useStoryblokBridge } from "@storyblok/nuxt-2"
import { mapStores } from "pinia"
import { useGoStore } from "@/stores/go"

export default {
  data() {
    return {
      story: null,
    }
  },
  computed: {
    ...mapStores(useGoStore),
  },
  watch: {
    "$route.query.go_source"(value) {
      if (!value) {
        return
      }
      this.fetchStory()
    },
    "goStore.feature"(value) {
      if (!value) {
        return
      }
      this.fetchStory()
    },
  },
  mounted() {
    this.fetchStory()
  },
  methods: {
    async fetchStory() {
      this.story = null
      const slug = this.$route.query.go_source || this.goStore.feature

      if (!slug) {
        return
      }
      const { story } = await EvercamApi.storyblock.getStory({
        route: `/cdn/stories/evercam-go/${slug}`,
        params: "version=draft&cv=undefined",
      })
      this.story = story
      if (!this.story) {
        return
      }
      useStoryblokBridge(this.story.id, (newStory) => (this.story = newStory))
    },
  },
}
